<template>
  <div>
    <div class="ai-buttons">
      <TooltipSlot
        :title="charsLeft > 0 ? $t('article.ai_service.not_enough_chars', { charsLeft }) : undefined"
        width="100%"
      >
        <button
          type="button"
          class="btn btn-green btn-generate"
          :class="{ 'btn-generate--disabled': loading }"
          :disabled="submitDisabled"
          @click="generateText"
        >
            <span class="btn-generate__content">
              <IconMagicWand
                class="btn-generate__icon"
                :class="{ 'loading-animation': loading }"
              />
            </span>
            <span class="btn-generate__label">
              {{ submitButtonLabel }}
            </span>
        </button>
      </TooltipSlot>

      <button
        v-if="openModalWithResults && generatedItems && generatedItems.length > 0"
        type="button"
        class="btn btn-info"
        :disabled="loading"
        @click="$refs.aiSelectModal.open()"
      >
        {{ $t('article.ai_service.show_generated') }}
      </button>
    </div>
    <Textarea
      v-if="debugging"
      v-model="requestText"
      id="ai_generator_requestText"
      label="requestText"
      :maxlength="999999"
      :rows="10"
      show-counter
    />
    <Input
      v-if="debugging"
      v-model="area"
      id="ai_generator_area"
      label="area"
    />
    <AiSelectModal
      ref="aiSelectModal"
      :generated-items="uniqueKeywords.length <= 0 ? generatedItems : uniqueKeywords"
      :original-text="originalText"
      :generator-type="type"
      @selected="onSelected"
      @selectedTitles="onSelectedTitles"
      @generate="generateText"
      @addTags="addTags"
    />
  </div>
</template>

<script>
import { aiServiceApi, aiServiceApiV2 } from '@/api/aiService'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import AiSelectModal from '@/components/ai/AiSelectModal'
import NotifyService from '@/services/NotifyService'
import TooltipSlot from '@/components/tooltip/TooltipSlot'
import IconMagicWand from '@/assets/img/svg/wand-magic-sparkles-solid.svg?inline'

export default {
  name: 'AiGenerator',
  props: {
    article: {
      type: Object,
      required: true
    },
    type: {
      type: String, // 'title' | 'keyword' | 'perex' | 'socialMediaPost'
      required: true
    },
    submitButtonLabel: {
      type: String,
      required: true
    },
    uniqueKeywords: {
      type: Array,
      default: () => []
    },
    /**
     * If you leave 'area' empty the AI service will use a default.
     */
    area: {
      type: String,
      required: false
    },
    /**
     * Set either bodyBlocks, html or plainText.
     */
    bodyBlocks: {
      type: Array,
      required: false
    },
    /**
     * Set either bodyBlocks, html or plainText.
     */
    html: {
      type: String,
      required: false
    },
    /**
     * Set either bodyBlocks, html or plainText.
     */
    plainText: {
      type: String,
      required: false
    },
    minRequestTextLength: {
      type: Number,
      default: 500
    },
    openModalWithResults: {
      type: Boolean,
      default: false
    },
    originalText: {
      type: String,
      required: false
    },
    socialMediaPostParams: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Input,
    Textarea,
    TooltipSlot,
    AiSelectModal,
    IconMagicWand
  },
  data () {
    return {
      debugging: false,
      loading: false,
      requestText: '',
      generatedItems: []
    }
  },
  computed: {
    aiFeaturesUsagePayload () {
      if (!this.article) {
        return null
      }

      const payload = {
        userId: this.article.createdBy,
        siteId: this.article.site,
        articleId: this.article.id,
        articleDocumentId: this.article.documentId,
        aiFeatureType: ''
      }

      switch (this.type) {
        case 'title':
          payload.aiFeatureType = 'article_ab_testing_title'
          break
        case 'keyword':
          payload.aiFeatureType = 'article_keywords'
          break
        case 'perex':
          payload.aiFeatureType = 'article_perex'
          break
        case 'socialMediaPost':
          payload.aiFeatureType = 'article_social_media_post'
          break
        default:
          console.error('Unknown type: ', this.type)
          return null
      }

      return payload
    },
    charsLeft () {
      return this.minRequestTextLength - (this.requestText?.length ?? 0)
    },
    submitDisabled () {
      return this.loading || this.charsLeft > 0
    }
  },
  methods: {
    onSelected (selectedItem) {
      this.$refs.aiSelectModal.close()
      this.$emit('selected', selectedItem)
    },
    onSelectedTitles (selectedItem) {
      this.$refs.aiSelectModal.close()
      this.$emit('onSelectedTitles', selectedItem)
    },
    async generateText () {
      if (this.submitDisabled) {
        return
      }

      this.$store.dispatch('aiStore/logAiFeaturesUsage', this.aiFeaturesUsagePayload)
      this.article.setting.aiAssisted = true
      this.$emit('aiAssistLock')

      try {
        this.loading = true
        this.$refs.aiSelectModal.close()
        let response
        if (this.type === 'socialMediaPost') {
          response = await aiServiceApiV2().post(`/generator/${this.type}`, {
            articleTitle: this.article.field.title,
            postType: this.socialMediaPostParams.interactionType,
            promptVariables: {
              postLength: this.socialMediaPostParams.postLength?.toString()
            }
          })
        } else {
          response = await aiServiceApi().post(`/${this.type}`, {
            requestText: this.requestText,
            area: this.area
          })
        }
        const { generatedItems } = response.data
        this.$emit('generated', generatedItems)
        if (generatedItems?.length > 0) {
          if (this.openModalWithResults) {
            this.generatedItems = generatedItems
            this.$refs.aiSelectModal.open()
          }
        } else {
          NotifyService.setErrorMessage(this.$t('article.ai_service.no_generated_results'))
        }
      } catch (error) {
        console.error(error)
        NotifyService.setErrorMessage(error)
      } finally {
        this.loading = false
      }
    },
    addTags (tags) {
      this.$emit('addTags', tags)
    },
    getBodyBlocksText (bodyBlocks) {
      let html = ''
      for (const bodyBlock of bodyBlocks) {
        if (bodyBlock.properties) {
          const { text, items } = bodyBlock.properties
          if (text) {
            html = `${html}${text}\n`
          } else if (items && Array.isArray(items)) {
            for (const item of items) {
              html = `${html}${item}\n`
            }
          }
        }
      }
      return this.convertToPlainText(html)
    },
    convertToPlainText (html) {
      const div = document.createElement('div')
      div.innerHTML = html
      return div.textContent || div.innerText || html
    },
    setRequestText () {
      if (this.bodyBlocks) {
        this.requestText = this.getBodyBlocksText(this.bodyBlocks)
      } else if (this.html) {
        this.requestText = this.convertToPlainText(this.html)
      } else if (this.plainText) {
        this.requestText = this.plainText
      } else {
        throw Error('Missing required prop: bodyBlocks | html | plainText')
      }
    }
  },
  watch: {
    bodyBlocks: {
      deep: true,
      handler: function () {
        this.setRequestText()
      }
    },
    html () {
      this.setRequestText()
    },
    plainText () {
      this.setRequestText()
    }
  },
  created () {
    this.setRequestText()
  }
}
</script>
<style lang="scss">
.btn:disabled {
  cursor: default !important;
  &:hover {
    opacity: 0.65;
    box-shadow: unset;
  }
}
</style>

<style scoped lang="scss">
.ai-buttons {
  display: flex;
  gap: rem(5px)
}
.btn-generate {
  display: flex;
  gap: rem(5px);
  align-items: flex-end;
  &--disabled {
    cursor: default !important;
    opacity: 1 !important;
    background: rgba(65, 184, 131, 0.5);
  }
  &__content {
    width: rem(17px);
    height: rem(17px);
  }
  &__icon {
    fill: #FFFFFF;
  }
}

.loading-animation {
  animation: blick-load 1.8s linear infinite;
}
@keyframes blick-load {
  0% {
    fill: rgba(220, 47, 67, 0.68);
    transform:rotate(0deg);
  }
  50% {
    fill: #636de5;
  }
  100% {
    fill: rgba(220, 47, 67, 0.68);
    transform:rotate(360deg);
  }
}

</style>
